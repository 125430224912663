import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { HeaderLayout } from "./headerLayout"

interface HeaderProps {
  siteTitle?: string
}

const Header = ({ siteTitle = "" }: HeaderProps) => {
  return (
    <>
      <HeaderLayout>
        <h2
          sx={{
            marginTop: 0,
            marginBottom: ["1rem", "1.6rem"],
            color: "tmcYellow",
            fontSize: ["1.1rem", "1.5rem"],
            lineHeight: ["1.4", "1.6", "1.7"],
            letterSpacing: 2,
          }}
        >
          Z vděku, k místu kde jsme vyrostli, <br />
          servírujeme menu inspirované vzpomínkami <br />
          na námi oblíbená jídla
        </h2>

        <h1
          sx={{
            marginTop: ["2rem", "3rem"],
            fontSize: ["2rem", "4rem", "5rem"],
            fontWeight: "bold",
            color: "white",
          }}
        >
          Ze Železných Hor
        </h1>
      </HeaderLayout>

      <div
        sx={{
          width: [150, 250],
          margin: "0 auto",
          transform: "translateY(-40%)",
        }}
      >
        <StaticImage
          src="../images/logo.png"
          layout="constrained"
          width={250}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          placeholder="none"
        />
      </div>
    </>
  )
}

export default Header
