import { MdOutlineFacebook } from "react-icons/md"

const Footer = () => {
  return (
    <footer
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr", "1fr 1fr 1fr"],
        gap: "1.5rem",

        fontSize: `0.7rem`,
        padding: ["0.5rem 1.5rem", "0.5rem 3rem"],
        textAlign: "center",
        color: "gray",
        backgroundColor: "darkGrey",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <a
          href="https://www.facebook.com/menuzezeleznychhor"
          title="Facebook"
          sx={{
            color: "gray",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: ["center", "start"],
          }}
          target="_blank"
        >
          <MdOutlineFacebook size="1.6em" />
          <span sx={{ fontSize: "1.1em" }}>acebook</span>
        </a>
        <a
          href="mailto: menu@zezeleznychhor.cz"
          sx={{
            color: "gray",
            textAlign: ["center", "left"],
            fontSize: "1.1em",
          }}
        >
          menu@zezeleznychhor.cz
        </a>
      </div>
      <div>
        © {new Date().getFullYear()}
        <div>
          Icons made by{" "}
          <a
            href="https://www.freepik.com"
            title="Freepik"
            sx={{ color: "gray" }}
          >
            Freepik
          </a>{" "}
          from{" "}
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
            sx={{ color: "gray" }}
          >
            www.flaticon.com
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
