import React from "react"
import { graphql, Link, PageProps } from "gatsby"
import { GiWineBottle, GiMoneyStack, GiHotMeal } from "react-icons/gi"
import { IoIosPeople, IoMdTime, IoMdCalendar, IoIosEye } from "react-icons/io"
import { IoLocationOutline, IoPersonAdd, IoHelpOutline } from "react-icons/io5"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Title } from "../components/title"
import { Contact } from "../components/contact"
import QA from "../components/qa"

type DataProps = {
  site: {
    buildTime: string
  }
}

const HomePage = ({ data }: PageProps<DataProps>) => (
  <Layout>
    <Title>18.11. Třemošnice</Title>
    <section sx={{ margin: "3rem 0" }}>
      {[
        {
          q: "O co jde?",
          a: "O pětichodovou večeři v Třemošnici.",
          icon: IoHelpOutline,
        },
        {
          q: "Jak to bude vypadat?",
          a: "Jeden stůl a 10 židlí.",
          icon: IoIosEye,
        },
        {
          q: "Kdy se to koná?",
          a: "V pátek 18.listopadu.",
          icon: IoMdCalendar,
        },
        {
          q: "V kolik a na jak dlouho to je?",
          a: "Večeře trvá přibližně 2h, můžete si zvolit čas od 17h nebo 19:30.",
          icon: IoMdTime,
        },
        {
          q: "Co budeme jíst?",
          a: "Menu se dozvíte až přímo na místě, bohužel ho nemůžeme měnit a tedy ani přizpůsobovat stravovacím omezením.",
          icon: GiHotMeal,
        },
        {
          q: "A pít?",
          a: "K jídlům máme připravené alko i nealko, vše je v ceně menu.",
          icon: GiWineBottle,
        },
        {
          q: "Kolik nás může přijít?",
          a: "Můžete si udělat rezervaci pro 1-4 osoby.",
          icon: IoIosPeople,
        },
        {
          q: "A kam?",
          a: "Místo se dozvíte po potvrzení a zaplacení rezervace.",
          icon: IoLocationOutline,
        },
        { q: "Kolik to stojí?", a: "800 Kč na hlavu.", icon: GiMoneyStack },
        {
          q: "Jak se můžu zůčastnit?",
          a: (
            <>
              Vyplňtě náš{" "}
              <Link
                to="#rezervace-mista"
                sx={{
                  color: "black",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                formulář pro rezervaci místa
              </Link>
              , my vám pošleme další informace.
            </>
          ),
          icon: IoPersonAdd,
        },
      ].map((qa, i) => (
        <QA {...qa} key={i} />
      ))}
    </section>

    <Contact />
  </Layout>
)

export default HomePage

export const Head = () => <Seo />

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
