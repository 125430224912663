import type { ReactNode, ComponentType } from "react"

interface QAProps {
  q: ReactNode
  a: ReactNode
  icon: ComponentType
  iconSize?: string
}

const QA = ({ q, a, icon: Icon }: QAProps) => (
  <div
    data-sal="zoom-in"
    data-sal-duration="400"
    data-sal-delay="300"
    data-sal-easing="ease-in-out"
    sx={{
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "1.6667rem",
    }}
  >
    <Icon
      sx={{
        flex: "0 0 auto",
        marginRight: "1rem",
        display: ["none", "block"],
      }}
      size="2.5em"
    />
    <div
      sx={{
        flex: "0 1 auto",
        display: "inline-flex",
        flexDirection: "column",
        padding: "0.5rem 0 1rem 0",
      }}
    >
      <h3 sx={{ margin: 0 }}>{q}</h3>
      <span
        sx={{
          color: "darkGrey2",
          marginTop: "1rem",
          marginBottom: 0,
          lineHeight: "1.5em",
          fontSize: 20,
        }}
      >
        {a}
      </span>
    </div>
  </div>
)

export default QA
