import React from "react"
import { useForm } from "@formspree/react"
import { GiCheckMark } from "react-icons/gi"
import { BiLoader } from "react-icons/bi"

import "./formspree.css"

const FORMSPREE_FORM_ID = "xqkjldoq"

/* 
 * Form testing code
 *
  const [state, setState] = React.useState({
    succeded: false,
    submitting: false,
  })

  const handleSubmit = e => {
    e.preventDefault()
    setState({
      succeded: false,
      submitting: true,
    })
    setTimeout(() => {
      setState({
        succeded: true,
        submitting: false,
      })
    }, 3000)
  } */

export const RvspForm = () => {
  const [state, handleSubmit] = useForm(FORMSPREE_FORM_ID)

  if (state.succeeded) {
    return (
      <p sx={{ textAlign: "center", fontSize: "1.1rem", margin: "5rem auto" }}>
        <GiCheckMark size="3em" />
        <br />
        Skvělé! Tvoji rezervaci jsme dostali a brzo se ti ozvem s dalšími
        podrobnostmi
      </p>
    )
  }

  return (
    <form id="fs-frm" onSubmit={handleSubmit}>
      <fieldset id="fs-frm-inputs">
        <label htmlFor="email">Váš email</label>
        <input id="email" type="email" name="email" required />

        <label htmlFor="members">Kolik vás bude?</label>
        <select name="members" id="members" defaultValue="1" required>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
        </select>

        <label htmlFor="time">Jaký preferujete čas?</label>
        <select name="time" id="time" defaultValue="" required>
          <option value="" disabled sx={{ opacity: 0.8 }}>
            Vyberte čas
          </option>
          <option value="17:00">17:00</option>
          <option value="19:30">19:30</option>
        </select>

        <label htmlFor="note">Chcete něco vzkázat?</label>
        <textarea id="note" name="note"></textarea>
      </fieldset>

      <button
        type="submit"
        disabled={state.submitting}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          padding: "0.75em 1rem",

          border: "1px solid #fffc",
          backgroundColor: "#000000b6",
          color: state.submitting ? "#fffb" : "white",

          opacity: state.submitting ? 0.7 : 1,

          ":hover": {
            backgroundColor: state.submitting ? undefined : "#000000c6",
          },
        }}
      >
        {state.submitting ? (
          <>
            <BiLoader
              size="1.2em"
              sx={{
                animationName: "spin",
                animationDuration: "2000ms",
                animationIterationCount: "infinite",
                animationTimingFunction: "linear",
                marginRight: "0.2rem",
              }}
            />
            Rezervuji
          </>
        ) : (
          "Rezerovat místo"
        )}
      </button>
    </form>
  )
}
