import type { PropsWithChildren } from "react"

export interface TitleProps {
  id?: string
}

export const Title = ({ id, children }: PropsWithChildren<TitleProps>) => (
  <h2
    id={id}
    sx={{
      margin: "0 auto 1em auto",
      textAlign: "center",
      color: "black",
      fontWeight: "bold",
      fontSize: "2em",
      letterSpacing: "1.5px",
    }}
  >
    {children}
  </h2>
)
