import React from "react"

import { RvspForm } from "./rvsp-form"
import { Title } from "./title"

export const Contact = () => {
  return (
    <section sx={{ margin: "6rem 0 3rem 0" }}>
      <Title id="rezervace-mista">Rezervace místa</Title>

      <RvspForm />

      <h3 sx={{ textAlign: "center" }}>Další dotazy?</h3>
      <p sx={{ textAlign: "center" }}>
        Piště je na email{" "}
        <a
          href="mailto: menu@zezeleznychhor.cz"
          sx={{ color: "darkGrey", textDecoration: "underline" }}
        >
          menu@zezeleznychhor.cz
        </a>
      </p>
    </section>
  )
}
